import React, { Component } from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetWheelBuilder, initializeWheelBuilder } from '../../../actions/wheelBuilder'
import Search from '../Search';
import WheelBuilderConfirmation from '../Search/WheelBuilderConfirmation'
import Product from '../Product'
import WheelProgress from '../WheelProgress'

class WheelBuilderSearch extends Component {
  componentDidMount() {
    const { initializeWheelBuilder, match, location } = this.props
    let category = null
    if (['falg', 'felg'].includes(match.params.step))
      category = 'rim'
    if (['dack', 'dekk'].includes(match.params.step))
      category = 'tyre'
    initializeWheelBuilder(category, location.search)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.step !== this.props.match.params.step) {
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    this.props.resetWheelBuilder()
  }

  render() {
    const { isInitialized, match } = this.props
    const { params } = match

    if (!isInitialized)
      return null

    return (
      <div className="container">
        <WheelProgress />
        <WheelBuilderContent step={params.id && params.slug ? 'product' : params.step} match={this.props.match} location={this.props.location} />
      </div>
    )
  }
}

const WheelBuilderContent = ({ step, match, location }) => {
  if (step === 'klar')
    return <WheelBuilderConfirmation />
  if (step === 'product')
    return <Product match={match} isWheelBuilder />
  return <Search isWheelBuilder location={location} slug="home.hjul" />
}

const mapStateToProps = state => ({
  isInitialized: state.wheelBuilder.isInitialized,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  resetWheelBuilder: () => resetWheelBuilder(),
  initializeWheelBuilder: (category, search) => initializeWheelBuilder(category, search),
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WheelBuilderSearch)
