import { push } from 'react-router-redux'
import qs from 'query-string'

export const updateQueryParams = (currentQuery, newQueryParams, removeParams) => {
  const cleanQuery = Object.entries(qs.parse(currentQuery, { arrayFormat: 'bracket' }))
  .reduce((acc, [key, value]) => {
    // NOTE: Remove any keys that already have bracket notation
    //       Preventing situations where [] keeps appending e.g. params[][][]
    const cleanKey = key.replace(/\[+\]*/g, '');
    acc[cleanKey] = value;
    return acc;
  }, {})
  if (removeParams)
    removeParams.forEach(param => delete cleanQuery[param])
  const params = Object.assign({}, cleanQuery, newQueryParams)
  // NOTE: Backend handle array with bracket notation, so we use this
  return push({
    search: qs.stringify(params, { arrayFormat: 'bracket' })
  })
}

export const pathTranslate = (path) => {
  // NOTE: Currently only se > nb
  const pathTranslations = {
    '/dack': '/dekk',
    '/falg': '/felg',
    '/hjul/falg': '/hjul/felg',
    '/hjul/dack': '/hjul/dekk'
  }
  let newPath = path
  // Loop through all keys in the replacements
  for (const [key, value] of Object.entries(pathTranslations)) {
    const regex = new RegExp(`^${key}(\\b|\\?|$)`) // match exact path or query string start
    if (regex.test(newPath)) {
      newPath = newPath.replace(key, value);
      break // stop at the first match
    }
  }

  return newPath
}

export const menuTranslate = (menu) => {
  // NOTE: Currently only se > nb
  let _menu = menu

  if (menu?.length) {
    _menu = menu.map(obj => {
      return { ...obj, url: pathTranslate(obj.url) }
    })
  }

  return _menu
}

export const pushTranslate = (path, locale = null) => {
  // NOTE: Currently only se > nb
  const pathname = locale === 'nb'
    ? pathTranslate(path)
    : path

  return pathname
}