import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { pathTranslate } from 'helpers/routing'

const FrontPage  = (props) => {
  let landingUrl = props.frontPageConfig.url
  if (props.shop.locale === 'nb') {
    landingUrl = pathTranslate(props.frontPageConfig.url)
  }

  return (
    <div>
      <Redirect to={landingUrl}/>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    frontPageConfig: state.menu.frontPage,
    shop: state.shop
  }
}

export default connect(mapStateToProps)(FrontPage)
