import React from 'react'
import Cart from '../Cart'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import styles from './styles.less'
import HeaderNavItem from '../HeaderNavItem'
import { Link } from 'react-router-dom'
import Sidebar from '../Sidebar'
import { HEADLESS } from '../../../constants/headlessSetting'
import classnames from 'classnames'
import navItemStyles from '../HeaderNavItem/styles.less'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { menuTranslate } from 'helpers/routing'

export const Header = (props) => {
  if (props.shop.id) {
    const { menu, shop, headless } = props
    let menuLeft = menu.left, menuRight = menu.right
    if (shop.locale === 'nb') {
      // NOTE: This is temp solution during peak season to minimize code change side effects
      //       mainly to facilitate SEO for nowergian's webshop
      // TODO: Accomodate i18n on url, slug and related
      menuLeft = menuTranslate(menu.left)
      menuRight = menuTranslate(menu.right)
    }

    const itemsLeft = menuLeft.map((item, index) => <HeaderNavItem key={index} item={item}/>)
    const itemsRight = menuRight.map((item, index) => <HeaderNavItem key={index} item={item}/>)

    let phoneNumber
    if (shop.phoneNumber)
      phoneNumber = <span><FontAwesomeIcon icon={faPhone} className={styles.phoneIcon} />{shop.phoneNumber}</span>

    // TODO: remove when phone number / top text is added to backend
    if (shop.webshopId === 25)
      phoneNumber = <span><FontAwesomeIcon icon={faPhone} className={styles.phoneIcon} />040-688-76-51</span>

    const navbar = (
        <div className={classnames([styles.fullWidth, 'background-dark', 'header-navbar'])}>
          <div className={classnames([styles.navbarContainer, "container"])}>
            <div className={classnames('nav-left', styles.navLeft)}>
              {itemsLeft}
            </div>
            <div className={classnames('nav-right', styles.navRight)}>
              {itemsRight}
              <li className={classnames('header-nav-item', navItemStyles.navItem)}><Cart className={styles.headlessCart} /></li>
            </div>
        </div>
      </div>
    )
    if (headless === HEADLESS.HIDE_ALL)
      return (
        <header className={classnames("container", "headless-header", styles.headlessHeader)}>
          <Row>
            <Col md="1" mdOffset="11">
              <Cart />
            </Col>
          </Row>
        </header>
      )
    else if (headless === HEADLESS.SHOW_MENU_ONLY)
      return (
        <header className={classnames('header', styles.header)}>
          {navbar}
        </header>
      )
    return (
      <header className={classnames('header', styles.header)}>
        <div className={`${styles.fullWidth} background-normal`}>
          <Row className={classnames('header-wrapper', styles.wrapperHeader)}>
            <Col md={2} xs={12} className={classnames('phone-number-container', styles.phoneNumber)}>{phoneNumber}</Col>
            <Col md={8} xs={12} className={classnames('header-container', styles.headerContainer)}>
              <Row className={styles.flexSpaceBetween}>
                <Col sm={12} className={classnames('logo-container', styles.logo)}>
                  <Link to="/" className={classnames('logo', styles.logotypeImage)}>
                    <img src={props.shop.style.logoUrl} className="logo-image" alt="/images/logotype.png"/>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col md={2} xs={12} className={classnames('sidebar-container', styles.sidebarContainer, styles.pullRight)}>
              <Sidebar className={classnames('sidebar', styles.sidebar)} options={menuRight}/>
              <Cart />
            </Col>
          </Row>
        </div>
        <div className={classnames([styles.fullWidth, 'background-dark', 'header-navbar'])}>
          <div className={classnames([styles.navbarContainer, "container"])}>
            <div className={classnames('nav-left', styles.navLeft)}>
              {itemsLeft}
            </div>
            <div className={classnames('nav-right', styles.navRight)}>
              {itemsRight}
            </div>
          </div>
        </div>
      </header>
    )
  }
}

const mapStateToProps = state => {
  return {
    shop: state.shop,
    menu: state.menu,
    headless: state.shop.headless,
  }
}

export default connect(
  mapStateToProps
)(Header)
